import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5';
import * as firebase from 'firebase/app';
import { MatLabel } from '@angular/material/form-field';
import { variable } from '@angular/compiler/src/output/output_ast';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
// import 'firebase/firestore'; wtf is this?
// import 'rxjs/add/observable/of';
// import { of } from 'rxjs';
// import { Observable } from 'rxjs;'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  calcReady = false;
  pickerFrom = "";
  pickerTo = "";
  queryFrom;
  queryTo;
  showMenu = "";
  title = "f.othercooked.com";
  items: any;
  items2: any;
  items3: any;
  currentBoardName = "";//Administrator";
  currentBoardId = "";
  language = "en";
  history = false;
  urlData: any;
  placeId = "";
  realId = "";
  place: any = {};
  placeName = "";
  placeType = "";
  logged = null;
  password: any;
  page = "front";
  boards = [];
  currentTable: any;
  currentMonth = null;
  currentYear = null;
  Baskets: any;// = [];
  BasketsNumbers = {};
  Months = [];
  totalSold = 0;

  reportType = 0;

  stats_total_goods = 0;
  stats_total = 0;
  stats_total_vat14 = 0;
  stats_total_vat24 = 0;
  stats_total_by_cash = 0;
  stats_total_by_card = 0;
  stats_total_by_online = 0;
  stats_total_cancelled = 0;
  stats_total_clients = 0;
  stats_average_check = 0;
  stats_total_qty = 0;
  stats_total_by_cash_qty = 0;
  stats_total_by_card_qty = 0;
  stats_total_by_online_qty = 0;
  stats_total_cancelled_qty = 0;
  stats_total_guests = 0;
  stats_average_sell = 0;
  stats_vat14 = 0;
  stats_vat14_wo = 0;
  stats_vat14_total = 0;
  stats_vat24 = 0;
  stats_vat24_wo = 0;
  stats_vat24_total = 0;
  stats_goods = {};

  dataSource2: any;
  dataSource = [
    { code: 1, name: 'Hydrogen', vat: 14, price: 1.0079, quantity: 13, total: 1234.00 }
    // {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
    // {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
    // {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
    // {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
    // {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
    // {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
    // {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
    // {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
    // {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
    // {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
  ];
  displayedColumns: string[] = ['code', 'name', 'vat', 'price', 'quantity', 'total'];
  displayedColumns2: string[] = ['code'];

  constructor(public db: AngularFirestore, public auth: AngularFireAuth,) {

    console.log("1326 :)");

  }


  async ngOnInit() {

    // this.currentMonth = this.monthFormat(new Date().getMonth() + 1);
    // console.log(this.currentMonth);
    // this.currentYear = new Date().getFullYear();
    // console.log(this.currentYear);

    // this.Months.push(this.currentMonth);
    // this.Months.push(this.monthFormat(new Date().getMonth()));
    // this.Months.push(this.monthFormat(new Date().getMonth() - 1));

    this.placeId = window.location.pathname.split('/')[1];
    console.log(this.placeId);

    if (!this.placeId) {
      this.page == "front";
      return;
    }

    if (this.placeId == "app") {
      this.page = "app";
      return;
    }

    // big else here 
    this.page = "board";

    this.db.doc('Places/' + this.placeId).ref.get().then(snap => {
      if (snap.exists) {
        var data: any = snap.data();
        this.place = data;
        this.placeName = data.Name;
        this.placeType = data.Type;
      }
    });




  }




  async Login() {
    console.log(this.password + "/" + this.placeId);

    if (!this.password)
      return;


    await this.db.collection('Places/' + this.placeId + '/Boards').ref
      .where("Password", "==", Md5.hashStr(this.password)).get()
      .then(async snap => {
        //console.log(snap.docs.length);

        if (snap.docs.length > 0) {
          //console.log(snap.docs[0].data());

          // sigin in with email and pass MOVE THIS TO PROPER AUTH
          this.auth.signInWithEmailAndPassword(this.placeId.toLowerCase() + "@othercooked.com", this.placeId).then(u => {
            console.log("LOGGED! " + u.user.uid);
            this.realId = u.user.uid;

            // this.MonthChange(this.currentMonth);

          });

          var data: any = snap.docs[0].data();
          this.logged = data.Name;
          this.currentBoardName = data.Name;
          this.currentBoardId = snap.docs[0].id;



        }
      });



  }

  Logout() {
    this.logged = null;
    this.password = null;
    this.currentBoardName = null;

    this.auth.signOut();
  }



  Recalc() {
    this.reportType = 1;

    // const event = new Date();
    // const expirationDate = admin.firestore.Timestamp.fromDate(event);
    // const query = collectionRef.where('startTime', '<=', expirationDate)

    console.log(this.queryFrom);
    console.log(this.queryTo);

    console.log(firebase.firestore.Timestamp.fromDate(this.queryFrom));
    this.calcReady = true;

    this.db.collection('Orders2').ref
      // .where("User.RealId", "==", this.realId)
      .where("PlaceId", "==", this.placeId)
      .where("Created", ">=", firebase.firestore.Timestamp.fromDate(this.queryFrom))
      .where("Created", "<=", firebase.firestore.Timestamp.fromDate(this.queryTo))
      .where("Status", "in", ["Paid", "Cancelled"])
      // .limit(10)
      .get()
      .then((snap) => {
        console.log(snap.docs.length);

        // drop values
        this.stats_total = 0;
        this.stats_total_qty = 0;
        this.stats_total_by_card = 0;
        this.stats_total_by_card_qty = 0;
        this.stats_total_by_cash = 0;
        this.stats_total_by_cash_qty = 0;
        this.stats_total_by_online = 0;
        this.stats_total_by_online_qty = 0;
        this.stats_total_cancelled = 0;
        this.stats_total_cancelled_qty = 0;
        this.stats_total_guests = 0;
        this.stats_average_sell = 0;
        this.stats_vat14 = 0;
        this.stats_vat14_wo = 0;
        this.stats_vat14_total = 0;
        this.stats_vat24 = 0;
        this.stats_vat24_wo = 0;
        this.stats_vat24_total = 0;
        this.stats_goods = {};

        snap.docs.forEach(order => {

          var o: any = order.data();

          if (o.PaidBy == "Cancelled") {
            this.stats_total_cancelled += o.Total;
            this.stats_total_cancelled_qty++;
            return; // if cancelled just conunt IT and continue
          }

          this.stats_total_guests++;

          this.stats_total += o.Total;
          this.stats_total_qty++;

          if (o.PaidBy == "PaidByCard") {
            this.stats_total_by_card += o.Total;//parseInt(value.toString());
            this.stats_total_by_card_qty++;
          }
          if (o.PaidBy == "PaidByCash") {
            this.stats_total_by_cash += o.Total;
            this.stats_total_by_cash_qty++;
          }
          if (o.PaidBy == "Online") {
            console.log("---------------------");
            this.stats_total_by_online += o.Total;
            this.stats_total_by_online_qty++;
          }


          // foreach product
          o.Products.forEach(p => {

            if (p.VAT == 14) {
              var priceTotal = p.Price * p.Quantity;
              this.stats_vat14_wo += (priceTotal - (priceTotal / 100 * p.VAT));
              this.stats_vat14 += (priceTotal / 100 * p.VAT);
              this.stats_vat14_total += priceTotal;
            }
            if (p.VAT == 24) {
              var priceTotal = p.Price * p.Quantity;
              this.stats_vat24_wo += (priceTotal - (priceTotal / 100 * p.VAT));
              this.stats_vat24 += (priceTotal / 100 * p.VAT);
              this.stats_vat24_total += priceTotal;
            }
            // this.stats_goods[p.Id] = {
            //   name: p.Name,
            //   price: p.Price,
            //   qty: p.Quantity,
            //   total: p.Price * p.Quantity
            // };
            if (this.stats_goods[p.Id]) {
              this.stats_goods[p.Id].qty += p.Quantity;
              this.stats_goods[p.Id].total += (p.Price * p.Quantity);
            }
            else
              this.stats_goods[p.Id] = {
                name: p.Name,
                price: p.Price,
                qty: p.Quantity,
                total: p.Price * p.Quantity
              };


            // foreach extras
            p.Extras.forEach(e => {

              if (e.VAT == 14) {
                var priceTotal = e.Price * e.Quantity;
                this.stats_vat14_wo += (priceTotal - (priceTotal / 100 * e.VAT));
                this.stats_vat14 += (priceTotal / 100 * e.VAT);
                this.stats_vat14_total += priceTotal;
              }
              if (e.VAT == 24) {
                var priceTotal = e.Price * e.Quantity;
                this.stats_vat24_wo += (priceTotal - (priceTotal / 100 * e.VAT));
                this.stats_vat24 += (priceTotal / 100 * e.VAT);
                this.stats_vat24_total += priceTotal;
              }

            });

          });

        });

        var temp_goods = [];
        for (const [key, value] of Object.entries(this.stats_goods)) {
          var pr: any = value;
          temp_goods.push(pr);
        }

        this.stats_average_sell = this.stats_total / this.stats_total_guests;
        this.items2 = of(temp_goods);

      });

  }

  Recalc2() {

    this.reportType = 2;

    // const event = new Date();
    // const expirationDate = admin.firestore.Timestamp.fromDate(event);
    // const query = collectionRef.where('startTime', '<=', expirationDate)

    console.log(this.queryFrom);
    console.log(this.queryTo);

    console.log(firebase.firestore.Timestamp.fromDate(this.queryFrom));
    this.calcReady = true;

    this.db.collection('Orders2').ref
      // .where("User.RealId", "==", this.realId)
      .where("PlaceId", "==", this.placeId)
      .where("Created", ">=", firebase.firestore.Timestamp.fromDate(this.queryFrom))
      .where("Created", "<=", firebase.firestore.Timestamp.fromDate(this.queryTo))
      .where("PaidBy", "==", "Online")
      .where("Status", "in", ["Paid"])
      // .limit(10)
      .get()
      .then((snap) => {
        console.log(snap.docs.length);

        // drop values
        this.stats_total = 0;
        this.stats_total_qty = 0;
        this.stats_total_by_card = 0;
        this.stats_total_by_card_qty = 0;
        this.stats_total_by_cash = 0;
        this.stats_total_by_cash_qty = 0;
        this.stats_total_by_online = 0;
        this.stats_total_by_online_qty = 0;
        this.stats_total_cancelled = 0;
        this.stats_total_cancelled_qty = 0;
        this.stats_total_guests = 0;
        this.stats_average_sell = 0;
        this.stats_vat14 = 0;
        this.stats_vat14_wo = 0;
        this.stats_vat14_total = 0;
        this.stats_vat24 = 0;
        this.stats_vat24_wo = 0;
        this.stats_vat24_total = 0;
        this.stats_goods = {};

        // foreach order
        snap.docs.forEach(order => {

          // drop values
          var oNetto = 0;
          var oTotal = 0;
          var oVat14 = 0;
          var oVat14netto = 0;
          var oVat14total = 0;
          var oVat24 = 0;
          var oVat24netto = 0;
          var oVat24total = 0;
          var oDiscount = 0;
          var oDiscountPercentage = 0;

          var o: any = order.data();

          if (o.PaidBy == "Cancelled") {
            this.stats_total_cancelled += o.Total;
            this.stats_total_cancelled_qty++;
            return; // if cancelled just conunt IT and continue
          }

          this.stats_total_guests++;

          this.stats_total += o.Total;
          this.stats_total_qty++;

          if (o.PaidBy == "Online") {
            console.log("---------------------");
            this.stats_total_by_online += o.Total;
            this.stats_total_by_online_qty++;
          }

          // foreach product
          o.Products.forEach(p => {

            // catch discount
            if (p.Id == "DiscountOnlinePayment") {
              console.log("===");
              oDiscount = Math.abs(p.Price);
              oDiscountPercentage = parseFloat((oDiscount / o.Total * 100).toFixed(0));
              console.log("Discount: " + oDiscount + " / " + oDiscountPercentage + "%");
              // cannot skip so lets continue as is
            }

            // drop values
            var pNetto = 0;
            var pTotal = 0;
            var pVat14 = 0;
            var pVat24 = 0;

            if (p.VAT == 14) {
              pTotal = p.Price * p.Quantity;
              pNetto += (pTotal / (100 + p.VAT)) * 100;
              pVat14 += pTotal - pNetto;

              oVat14 += pVat14;
              oVat14netto += pNetto;
              oVat14total += pTotal;

              console.log("===");
              console.log("brutto " + pTotal);
              console.log("netto " + pNetto);
              console.log("vat14 " + pVat14);
            }
            if (p.VAT == 24) {
              pTotal = p.Price * p.Quantity;
              pNetto += (pTotal / (100 + p.VAT)) * 100;
              pVat24 += pTotal - pNetto;

              oVat24 += pVat24;
              oVat24netto += pNetto;
              oVat24total += pTotal;

              console.log("===");
              console.log("brutto " + pTotal);
              console.log("netto " + pNetto);
              console.log("vat24 " + pVat24);
            }

            // foreach extras
            p.Extras.forEach(e => {

              // drop values
              var eNetto = 0;
              var eTotal = 0;
              var eVat14 = 0;
              var eVat24 = 0;

              if (e.VAT == 14) {
                eTotal = e.Price * e.Quantity;
                eNetto += (eTotal / (100 + e.VAT)) * 100;
                eVat14 += eTotal - eNetto;

                pVat14 += eVat14;
                oVat14 += eVat14;
                oVat14netto += eNetto;
                oVat14total += eTotal;

                // console.log("===");
                // console.log("brutto " + pTotal);
                // console.log("netto " + pNetto);
                // console.log("vat14 " + pVat14);

                // var priceTotal = e.Price * e.Quantity;
                // this.stats_vat14_wo += (priceTotal - (priceTotal / 100 * e.VAT));
                // this.stats_vat14 += (priceTotal / 100 * e.VAT);
                // this.stats_vat14_total += priceTotal;

                // oVat14 += this.stats_vat14;
              }
              if (e.VAT == 24) {
                eTotal = e.Price * e.Quantity;
                eNetto += (eTotal / (100 + e.VAT)) * 100;
                eVat24 += eTotal - eNetto;

                pVat24 += eVat24;
                oVat24 += eVat24;
                oVat24netto += eNetto;
                oVat24total += eTotal;

                // console.log("===");
                // console.log("brutto " + pTotal);
                // console.log("netto " + pNetto);
                // console.log("vat14 " + pVat14);

                // var priceTotal = e.Price * e.Quantity;
                // this.stats_vat24_wo += (priceTotal - (priceTotal / 100 * e.VAT));
                // this.stats_vat24 += (priceTotal / 100 * e.VAT);
                // this.stats_vat24_total += priceTotal;

                // oVat24 += this.stats_vat24;
              }

            });

          });

          oTotal = o.Total;
          oNetto = oTotal - oVat24 - oVat14;

          // reduce vat by discount percentage
          if (oDiscountPercentage > 0) {
            console.log("= Doing discount =");
            console.log("was: " + oVat14netto + " / " + oVat14 + " / " + oVat14total);
            oVat14netto = oVat14netto - (oVat14netto / 100 * oDiscountPercentage);
            oVat14 = oVat14 - (oVat14 / 100 * oDiscountPercentage);
            oVat14total = oVat14total - (oVat14total / 100 * oDiscountPercentage);
            console.log("now: " + oVat14netto + " / " + oVat14 + " / " + oVat14total);

            oVat24netto = oVat24netto - (oVat24netto / 100 * oDiscountPercentage);
            oVat24 = oVat24 - (oVat24 / 100 * oDiscountPercentage);
            oVat24total = oVat24total - (oVat24total / 100 * oDiscountPercentage);

          }

          this.stats_vat14_wo += oVat14netto;// oTotal - oVat14;
          this.stats_vat14 += oVat14;
          this.stats_vat14_total += oVat14total; //oTotal;

          this.stats_vat24_wo += oVat24netto; //oTotal - oVat24;
          this.stats_vat24 += oVat24;
          this.stats_vat24_total += oVat24total; //oTotal;


          this.stats_goods[o.OrderId] = {
            id: o.OrderId,
            total: oTotal,
            netto: oNetto,
            vat14: oVat14,
            vat24: oVat24
          };

        });

        var temp_goods = [];
        for (const [key, value] of Object.entries(this.stats_goods)) {
          var pr: any = value;
          temp_goods.push(pr);
        }

        this.stats_average_sell = this.stats_total / this.stats_total_guests;
        this.items3 = of(temp_goods);

      });

  }

  exportAsPDF(div_id) {
    // var printContents = document.getElementById(div_id).innerHTML;
    // var originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
    // window.print();
    // document.body.innerHTML = originalContents;
    // // window.location.reload();

    window.print();
    return;


    let data = document.getElementById(div_id);
    html2canvas(data).then(canvas => {
      // const imgWidth = 208;
      // const pageHeight = 295;
      // const imgHeight = canvas.height * imgWidth / canvas.width;
      // const heightLeft = imgHeight;
      // const contentDataURL = canvas.toDataURL('image/png');
      // const pdf = new jsPDF('p', 'mm', 'a4'); 
      // const position = 0;
      // pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      // pdf.save('report.pdf'); 

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      var imgWidth = 200;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF('p', 'mm', 'a4');
      var position = 0;
      doc.addImage(imgData, 'JPEG', 5, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 5, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save('report.pdf')

    });
  }

}
